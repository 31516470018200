.loading-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: 0.2%;
    /* left: 50%; */
    z-index: 1050;
    background-color: rgba(158, 156, 156, 0.8);
  }
  
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .visibal {
    display: block;
  }
  
  .notvisibal {
    display: none;
  }