.iti__country-list {
  width: 14rem !important;
  overflow: auto;
  scrollbar-width: thin;
}

.input-group-prepend {
  display: flex;
}

#input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

}

#conNum {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.custom-toast {
  width: 330px !important;
  /* Adjust the width as needed */
}

.cart-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}