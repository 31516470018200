.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 5rem; /* Adjust this value to the height of your fixed header */
}

.content-container {
  flex: 1;
  /* overflow-y: auto;
  padding: 20px; */
}

