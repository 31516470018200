.log-1{
    display: flex;
    gap: 0.6rem;
    align-items: center;
}

.log-btn{
    background-color: #ccc6;
    cursor: text !important;
}

.btn-new{
    background-color: #61ce70;
    cursor: text !important;
    padding: 3px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    align-items: center;
    width: 7rem;
    justify-content: center;
    pointer-events: none;
}

.btn-add{
    background-color: #4E89DD;
    cursor: text !important;
    padding: 3px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    align-items: center;
    /* width: 7rem; */
    justify-content: center;
    pointer-events: none;
}

.btn-enhance{
    background-color: #AE61CE;
    cursor: text !important;
    padding: 3px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    align-items: center;
    /* width: 7rem; */
    justify-content: center;
    pointer-events: none;
}

.btn-improve{
    background-color: #4EBDDD;
    cursor: text !important;
    padding: 3px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    align-items: center;
    /* width: 7rem; */
    justify-content: center;
    pointer-events: none;
}

.btn-added{
    background-color: black;
    cursor: text !important;
    padding: 3px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    align-items: center;
    /* width: 5rem; */
    justify-content: center;
    pointer-events: none;
}

.btn-improve:hover{
    background-color: #4EBDDD;
    cursor: text !important;
    color: white;
}

.btn-added:hover{
    background-color: black;
    cursor: text !important;
    color: white;
}

.btn-new:hover{
    background-color: #61ce70;
    cursor: text !important;
    color: white;
}

.btn-add:hover{
    background-color: #4E89DD;
    cursor: text !important;
    color: white;
}

.btn-enhance:hover{
    background-color: #AE61CE;
    cursor: text !important;
    color: white;
}

.log-btn:hover{
    background-color: #ccc6;
    cursor: text !important;
}

.main-topic{
    font-weight: 600;
    font-size: x-large;
}

.sub-mainTopic{
    font-weight: 600;
    font-size: large;
}

.reports-content{
    display: flex;
    gap: 1%;
    align-items: baseline;
}
