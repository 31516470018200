.Home-Content {
    padding: 20px;
    /* background-color: #f8f9fa; */
    margin-top: 3.5rem;
    display: flex;
  }
  
.Home-Content3,.Home-Content4,.Home-Content5 {
    padding: 20px;
    margin-top: 3rem;
    display: flex;
  }

  .Home-Content6 {
    /* padding: 20px; */
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .Home-Content7 {
    /* padding: 20px; */
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  

  .spanBlack{
    color: #00023c;
    font-size: 42px;
}
  .spanFeature{
    color: #00023c;
}

.spanRed{
    color: #ff0000;
    font-size: 42px;
}
.span-Red{
    color: #ff0000;
}
  
  .Main-Content h1 {
    font-weight: 500;
    text-align: left;
  }
  
  .Sub-Content p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
  }
  
  #contact {
    margin-top: 20px;
    color:#ff0000 !important;
    background-color: white !important;
    font-weight: 500;
    width: 7rem;
    border:2px solid #ff0000 !important;
  }
  
  #frothcrm-img {
    width: 100%;
    height: auto;
  }
  
.card-imgs{
  width: 18%;
}

#card-Content{
  display: flex;
  flex-direction: column;
  gap:1%;
  align-items: center;
  /* width: 31%; */
  padding: 2%;
  margin-top: 2%;
  /* box-shadow: 0px 0px 3px 0px rgba(171.965625, 171.965625, 171.965625, 0.5); */
  border-radius: 7px;
  text-align: center;
}

#card-Content2{
  display: flex;
  flex-direction: column;
  gap:1%;
  align-items: center;
  /* width: 31%; */
  padding: 2%;
  /* margin-left: 2%; */
  /* box-shadow: 0px 0px 3px 0px rgba(171.965625, 171.965625, 171.965625, 0.5); */
  /* border-radius: 7px; */
  text-align: center;
}

#title-2{
  color: #ff0000;
}
#Container{
  margin-left: 1% !important;
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
}

.card-body {
  text-align: center;
}

.card-title {
  font-size: 19px;
  font-weight: 600;
  /* margin-bottom: 0.5rem; */
}

.card-text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.carousel-indicators li {
  background-color: #000;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #000;
}

.rounded-circle {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
